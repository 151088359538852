<template>
  <div>
    <base-page
      title="Service permissions"
      ctaText="Add permission"
      :breadcrumbs="breadcrumbs"
      @action="dialog = true"
    >
      <template #content>
        <permission-list
          @edit="edit"
        />
      </template>
    </base-page>

    <permission-form
      v-if="dialog"
      :dialog="dialog"
      :permission="permission"
      @close="close()"
    ></permission-form>
  </div>
</template>

<script>
export default {
  components: {
    'permission-list': () => import('@/views/iam/PermissionList.vue'),
    'permission-form': () => import('@/views/iam/PermissionForm.vue'),
  },

  data () {
    return {
      dialog: false,
      permission: null,
      breadcrumbs: [
        { text: 'Permissions', disabled: true, },
      ],
    }
  },

  methods: {
    close () {
      this.dialog = false
    },

    edit (permission) {
      this.permission = permission
      this.dialog = true
    }
  }
}
</script>